<template>
  <div>
    <ViewTemplate :table-props="mappingColumns" :project-param-id="projectParamId" :mapper-param-id="mapperParamId" :default-selected-columns="defaultSelectedColumns"/>
  </div>
</template>

<script>

import { pageViews, type } from "../utils/util";
import Store from "../vuex/store.js";
import TabContentView from "./TabContentView.vue";
import ViewTemplate from "@/components/ViewTemplate.vue";
export default {
  name: pageViews.TRANSITIONVIEW,
  methods: {
      setValuesToStore(type) {
          Store.commit("setType", type);
      }
  },
  mounted() {
      this.setValuesToStore(type.MAPPER_TRANSITION);
  },
  components: {
    TabContentView,
    ViewTemplate
  },
  data() {
    return {
      uploadConfigs: {
        action: "generator",
        actionLabel: "Generator",
        title: "Mapper Config Generator",
        type: type.MAPPER_TRANSITION,
        accept: ".zip",
      },
      mappingColumns: {
        type: type.MAPPER_TRANSITION,
        isMultiSelectionAllowed: true,
        defaultSortOrder: { name: "desc", label: "Descending" },
        defaultSortColumn: { name: "Process Location", label: "Process Location" },
        configs: [
          { name: "processLocation", label: "Process Location", order: 1, sort: true, default: true },
          { name: "processFile", label: "Process File", order: 2, sort: true, default: true },
          { name: "groupName", label: "Group Name", order: 3, sort: true, default: true },
          { name: "transitionFrom", label: "Transition From", order: 4, sort: true,default: true },
          { name: "transitionTo", label: "Transition To", order: 5, sort: true, default: true },
          { name: "conditionType", label: "Condition Type", order: 6, sort: true, default: true },
          { name: "condition", label: "Condition", order: 7, sort: true, default: true },
        ],
        actions: {
          viewPopup: { label: 'View Output' },
          download: { label: 'Download' },
          calculate: { label: 'Calculate Complexity' },
        },
      },
      defaultSelectedColumns: [ "processLocation", "processFile","groupName","transitionFrom","transitionTo","conditionType","condition"]
    };
  },
  props: ["projectParamId", "mapperParamId"],
}

</script>
