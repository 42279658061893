<template>
    <HeaderView />
    <router-view />
</template>

<script>
import HeaderView from './views/HeaderView.vue';

export default {
  name: 'App',
  components: {
    HeaderView
  }
};
</script>

<style>
body {
  margin-bottom: 0;
}
</style>
