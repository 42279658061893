<template>
  <div class="popup-overlay" @click="closePopup">
    <div class="popup-content" @click.stop>
      <div class="button-container-top">
        <div v-if="isEditable" class="dropdown dropdown1">
          <button type="button" ref="dropdownButton" class="btn btn-primary dropdownButton dropdown-toggle" data-bs-toggle="dropdown"  aria-expanded="false" :disabled="disableButton" >{{ this.activeTemplateName }}</button>  
          <ul class="dropdown-menu fade">
            <li v-for="template in templates" :key="template.id">
              <a class="dropdown-item" href="#" @click="fetchDropdownTemplates(template.id, template.name)">{{ template.name }}</a>
            </li>
          </ul>
        </div>
        <button v-if="isEditable" @click="resetData" class="btn-reset">
          <i class="fas fa-undo"></i>
        </button>
        <button @click="closePopup" class="close-button">&times;</button>
      </div>
      <div class="sticky-header">
        <div>
          <strong>{{ title }}</strong>
        </div>
      </div>
      <div class="tab-content">
        <div class="table-wrapper">
          <table class="complexity-table">
            <thead class="sticky-table-header">
              <tr>
                <th>#</th>
                <th>Components</th>
                <th>Small</th>
                <th>Medium</th>
                <th>Large</th>
                <th>X-Large</th>
                <th v-if="isEditable">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in editableComplexityData" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  <div v-if="row.errorComponent" class="error-message">{{ row.errorComponent }}</div>
                  <select v-if="isEditable" v-model="row.component" @blur="validateComponent(index)">
                    <option v-for="type in availableComponentTypes(index)" :key="type" :value="type">{{ type }}</option>
                  </select>
                  <span v-else>{{ row.component }}</span>
                </td>
                <td>
                  <div v-if="row.errorSmall" class="error-message">{{ row.errorSmall }}</div>
                  <input v-if="isEditable" v-model.number="row.small" @input="row.small = Math.max(0, parseInt(row.small))" @blur="validateComponent(index)" type="number" />
                  <span v-else>{{ row.small }}</span>
                </td>
                <td>
                  <div v-if="row.errorMedium" class="error-message">{{ row.errorMedium }}</div>
                  <input v-if="isEditable" v-model.number="row.medium" @input="row.medium = Math.max(0, parseInt(row.medium))" @blur="validateComponent(index)" type="number" />
                  <span v-else>{{ row.medium }}</span>
                </td>
                <td>
                  <div v-if="row.errorLarge" class="error-message">{{ row.errorLarge }}</div>
                  <input v-if="isEditable" v-model.number="row.large" @input="row.large = Math.max(0, parseInt(row.large))" @blur="validateComponent(index)" type="number" />
                  <span v-else>{{ row.large }}</span>
                </td>
                <td>
                  <div v-if="row.errorXLarge" class="error-message">{{ row.errorXLarge }}</div>
                  <input v-if="isEditable" v-model.number="row.xlarge" @input="row.xlarge = Math.max(0, parseInt(row.xlarge))" @blur="validateComponent(index)" type="number" />
                  <span v-else>{{ row.xlarge }}</span>
                </td>
                <td v-if="isEditable">
                  <button @click="confirmDelete(index)" class="btn-delete"></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="button-container">
        <button v-if="isEditable" @click="checkAndAddRow" class="add-button" :disabled="componentTypes.length <= editableComplexityData.length">
          <i class="fa-sharp fa-solid fa-plus fa-fade"></i> Add
        </button>
        <button @click="toggleEdit" class="edit-submit-button">
          <i v-if="!isEditable" class="fas fa-edit edit"></i> {{ isEditable ? 'Submit' : 'Edit' }}
        </button>
      </div>
    </div>
  </div>
  <div class="modal fade" id="saveTemplateModal" tabindex="-1" aria-labelledby="saveTemplateModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content" style="text-wrap: auto;">
        <div class="modal-header">
          <h5 class="modal-title" id="saveTemplateModalLabel">Save Template</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Would you like to save the metrics as a template? Click 'Cancel' if you prefer not to use the same in future.</p>
          <div class="mb-3">
            <input type="text" class="form-control" id="templateName" placeholder="Enter template name" v-model="this.selectedTemplateName">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="saveComplexityData">Cancel</button>
          <button type="button" class="btn btn-primary" id="saveTemplateButton" @click="saveTemplate(selectedTemplateName)">Save Template</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeOpearationRequest } from "@/services/apiServices";
import { action } from "../utils/util"; // Import action
import { toast } from "vue3-toastify";
import * as bootstrap from 'bootstrap'; // Import Bootstrap's JavaScript
export default {
  props: {
    userId: String,
    type: String,
    requestId: String,
    title: String,
    projectParamId: String
  },
  data() {
    return {
      originalComplexityData: [],
      complexityData: {},
      editableComplexityData: [],
      isEditable: false,
      isTemplateNameLoaded: false,
      templateChanged:false,
      activeTemplateName:'Saved Templates',
      disableButton: true,
      selectedTemplateName:'',
      componentTypes: [],
      cmpId: null,
      popup: {
        title: '',
        body: '',
        actionLabel: '',
        secondaryActionLabel: '',
        data: null
      },
      hasAddedRow: false,
      templates: [] // Add a new data property for templates
    };
  },
  methods: {
    async fetchComplexityData() {
      try {
        const payload = {
          userId: this.userId,
          type: this.type,
          requestId: this.requestId,
          projectId: this.projectParamId
        };
        const response = await makeOpearationRequest(this.$acclConfigs, "/load_complexity", payload);
        if (response.status === 200) {
          const data = response.data;
          this.cmpId = data.compId;
          this.complexityData = JSON.parse(data.complexityJson);
          this.transformComplexityData();
        }
      } catch (error) {
        console.error('Failed to fetch complexity data:', error);
        throw error;
      }
    },
    async fetchDropdownTemplates(templateId,templateName) {
      try {
        const payload = {
          userId: this.userId,
          templateId: templateId, // Assuming -1 to fetch all templates
          projectId: this.projectParamId
        };
        const response = await makeOpearationRequest(this.$acclConfigs, "/load_template", payload);
        if (response.status === 200) {
          this.complexityData = JSON.parse(response.data.complexityJson);
          const complexityData = JSON.parse(response.data.complexityJson);
          const isEmptyTemplate = Object.values(complexityData).every(size => Object.keys(size || {}).length === 0);
          if (isEmptyTemplate) {
            toast.error("Oops! No components are available for the selected template. Please try picking another one.", {
              autoClose: 5000
            });
            return;
          }
          this.activeTemplateName = templateName;          
          this.selectedTemplateName = templateName;
          this.templateChanged = true;          
          this.transformComplexityData();
          if (this.isEditable) {
            this.originalComplexityData = JSON.parse(JSON.stringify(this.editableComplexityData));
            await this.fetchComponentTypes();
            toast.success(`"${templateName}" is now active and ready to use`, {
              autoClose: 5000
            });
          }
          this.isDropdownOpen = false; // Close the dropdown
          const dropdownElement = this.$refs.dropdownButton;
          const dropdownInstance = bootstrap.Dropdown.getInstance(dropdownElement);
          if (dropdownInstance) {
            dropdownInstance.hide();
          }
        } else {
          toast.error("Failed to load templates.", {
            autoClose: 5000
          });
        }
      } catch (error) {
        console.error('Failed to load templates:', error);
        toast.error("Failed to load templates.", {
          autoClose: 5000
        });
      }
    },
    async loadTemplateNames() {
      try {
        const payload = {
          userId: this.userId,
          type: this.type,
          requestId: this.requestId,
          projectId: this.projectParamId
        };
        
        if (this.isTemplateNameLoaded) {
          this.isTemplateNameLoaded = !this.isTemplateNameLoaded;
          return;
        }
        const response = await makeOpearationRequest(this.$acclConfigs, "/load_template_names", payload);
        if (response.status === 200) {
          this.templates = response.data.templateNames; // Assuming the response contains a 'templates' array
          this.isTemplateNameLoaded = !this.isTemplateNameLoaded;
          if (this.templates.length === 0) {
            this.disableButton = true;
            this.activeTemplateName = "Templates are not available";
          }else{
            this.disableButton = false;
            this.activeTemplateName ="Saved Templates";
          }
        }
      } catch (error) {
        console.error('Failed to fetch template names:', error);
      }
    },
    async fetchComponentTypes() {
      if (this.componentTypes && this.componentTypes.length > 0) {
        return;
      }
      try {
        const payload = {
          userId: this.userId,
          type: this.type,
          requestId: this.requestId,
          projectId: this.projectParamId
        };
        const response = await makeOpearationRequest(this.$acclConfigs, "/load_components", payload);
        if (response.status === 200) {
          const data = response.data;
          this.componentTypes = data.component_types;
        }
      } catch (error) {
        console.error('Failed to fetch component types:', error);
        throw error;
      }
    },
    transformComplexityData() {
      const transformed = [];
      for (const [size, components] of Object.entries(this.complexityData)) {
        for (const [component, value] of Object.entries(components)) {
          let row = transformed.find(row => row.component === component);
          if (!row) {
            row = { component, small: 0, medium: 0, large: 0, xlarge: 0 };
            transformed.push(row);
          }
          if (size === 'x-large') {
            row.xlarge = value;
          } else {
            row[size] = value;
          }
        }
      }
      this.editableComplexityData = transformed;
    },
    transformBackToJson() {
      const result = {
        small: {},
        medium: {},
        large: {},
        'x-large': {}
      };
      this.editableComplexityData.forEach(row => {
        if (row.component) {
          result.small[row.component] = row.small;
          result.medium[row.component] = row.medium;
          result.large[row.component] = row.large;
          result['x-large'][row.component] = row.xlarge;
        }
      });
      return result;
    },
    showToast(type){
      this.emit
    },
    async saveComplexityData() {
      if (!this.validateData()) {
        return;
      }
      try {
        const payload = {
          cmptId: this.cmpId,
          complexityJson: JSON.stringify(this.transformBackToJson())
        };
        console.log('Request Payload:', payload);
        const response = await makeOpearationRequest(this.$acclConfigs, "/save_complexity", payload);
        if (response.status === 200 && !(JSON.stringify(this.originalComplexityData) === JSON.stringify(this.editableComplexityData) && this.templateChanged)) {        
          toast('<i class="fa-solid fa-thumbs-up fa-bounce"></i> Cancel confirmed. The current complexity calculation has been successfully saved', {
            autoClose: 5000,
            dangerouslyHTMLString: true
          });
        }else if (response.status === 200 && (JSON.stringify(this.originalComplexityData) === JSON.stringify(this.editableComplexityData) && this.templateChanged)) {        
          toast(`<i class="fa-solid fa-thumbs-up fa-bounce"></i> "${this.activeTemplateName}" has been successfully applied!`, {
            autoClose: 5000,
            dangerouslyHTMLString: true
          });
        } else {
          toast.error("Failed to save complexity data.", {
            autoClose: 5000
          });
        }
      } catch (error) {        
        toast.error("Failed to save complexity data.", {
            autoClose: 5000
          });
      }
    },
    async toggleEdit() {
      try {
        if (this.isEditable) {
          if (!this.validateData()) {
            this.editableComplexityData.forEach((row, index) => {
              this.validateComponent(index);
            });
            toast.error("Please fix the validation errors before submitting.", {
              autoClose: 5000
            });
            return;
          }
          if (JSON.stringify(this.originalComplexityData) === JSON.stringify(this.editableComplexityData) && !this.templateChanged) {
            toast.info("No changes made.", {
              autoClose: 5000
            });
          }else if(JSON.stringify(this.originalComplexityData) === JSON.stringify(this.editableComplexityData) && this.templateChanged){
            this.saveComplexityData();

          } else {
            this.openSaveTemplateModal();
          }
        } else {
          this.isTemplateNameLoaded = false;
          await this.loadTemplateNames(); // Call loadTemplateNames when entering edit mode
        }
        this.isEditable = !this.isEditable;
        if (this.isEditable) {
          this.originalComplexityData = JSON.parse(JSON.stringify(this.editableComplexityData));
          this.templateChanged=false;
          await this.fetchComponentTypes();
        }
      } catch (error) {
        console.error('Failed to toggle edit mode:', error);
        throw error;
      }
    },
    openSaveTemplateModal() {
      this.selectedTemplateName = '';
      const modalElement = document.getElementById('saveTemplateModal');
      if (modalElement) {
        const modal = new bootstrap.Modal(modalElement);
        modal.show();
      } else {
        console.error('Modal element not found');
      }
    },
    resetData() {
      this.editableComplexityData = JSON.parse(JSON.stringify(this.originalComplexityData)); 
    },
    checkAndAddRow() {
      if (this.hasAddedRow && !this.validateData()) {
        this.editableComplexityData.forEach((row, index) => {
          this.validateComponent(index);
        });
        toast.error("Fill out fields and choose the appropriate options.", {
          autoClose: 5000
        });
      } else {
        this.addRow();
        this.hasAddedRow = true;
      }
    },
    addRow() {
      if (this.componentTypes.length > this.editableComplexityData.length) {
        this.editableComplexityData.push({ component: '', small: 0, medium: 0, large: 0, xlarge: 0, error: 'Component is required.' });
      } else {
        toast.success("All available components have been added.",{
            autoClose:5000
          })
      }
    },
    confirmDelete(index) {
      const data = {
        index,
        action: action.REMOVE_CALC_ROW
      }
      this.setConfirmModalValues(
        "Delete Confirmation",
        "Are you sure you want to delete this row?",
        "Delete",
        "Cancel",
        data
      );
      this.openModal();
    },
    removeRow(data) {
      console.log("removed row",data)
      this.editableComplexityData.splice(data.index, 1);
    },
    closePopup() {
      this.$emit('close');
      this.$emit('reloadData');
    },
    validateComponent(index) {
      const row = this.editableComplexityData[index];
      const components = this.editableComplexityData.map(row => row.component).filter(component => component);
      const uniqueComponents = new Set(components);
      row.errorComponent = '';
      row.errorSmall = '';
      row.errorMedium = '';
      row.errorLarge = '';
      row.errorXLarge = '';

      if (!row.component) {
        row.errorComponent = 'Component is required.';
      } else if (components.filter(component => component === row.component).length > 1) {
        row.errorComponent = 'Duplicate components are not allowed';
      } else if (row.medium <= row.small) {
        row.errorMedium = 'Medium should be greater than Small.';
      } else if (row.large <= row.small || row.large <= row.medium) {
        row.errorLarge = 'Large should be greater than Medium.';
      } else if (row.xlarge <= row.small || row.xlarge <= row.medium || row.xlarge <= row.large) {
        row.errorXLarge = 'X-Large should be greater than Large.';
      }
    },
    validateData() {
      const components = this.editableComplexityData.map(row => row.component).filter(component => component);
      const uniqueComponents = new Set(components);
      if (components.length !== uniqueComponents.size) {
        return false;
      }
      for (const row of this.editableComplexityData) {
        if (!row.component) {
          return false;
        } else if (row.medium <= row.small) {
          return false;
        } else if (row.large <= row.small || row.large <= row.medium) {          
          return false;
        } else if (row.xlarge <= row.small || row.xlarge <= row.medium || row.xlarge <= row.large) {          
          return false;
        }
      }
      return true;
    },
    setConfirmModalValues(title, body, actionLabel, secondaryActionLabel, data) {
      const confirmModalData = {
        title,
        body,
        actionLabel,
        secondaryActionLabel,
        data,
      };
      this.popup = { ...confirmModalData };
    },
    openModal() {
      this.$emit('openModal',this.popup)
    },
    handlePrimaryAction(data) {
      if (data && data.action) {
        if (data.action === action.REMOVE_CALC_ROW) {
          this.removeRow(data);
        }
      }
    },
    availableComponentTypes(index) {
      const selectedComponents = this.editableComplexityData.map((row, i) => i !== index ? row.component : null).filter(Boolean);
      return this.componentTypes.filter(type => !selectedComponents.includes(type));
    },
    async saveTemplate(templateName) {
      if (!templateName?.trim()) {
        toast.error("Template name is required.", {
          autoClose: 5000
        });
        return; 
      } else {
        console.log("Templates",this.templates)
        if (this.templates != null && this.templates.some(template => template.name === templateName)) {
          toast.error("Template with the same name already exists. Please specify a different name.", {
            autoClose: 5000
          });
          return;
        }
     }
      try {
        const payload = {
          userId: this.userId,
          templateId: -1, // Assuming -1 for new templates
          templateName: templateName,
          cmptId: this.cmpId,
          complexityJson: JSON.stringify(this.transformBackToJson())
        };
        const response = await makeOpearationRequest(this.$acclConfigs, "/save_template", payload);
        if (response.status === 200) {       
          toast(`<i class="fa-solid fa-thumbs-up fa-bounce"></i> "${templateName}" Template & Complexity calculation added successfully.`, {
            autoClose: 5000,
            dangerouslyHTMLString: true
          });
          this.fetchComplexityData(); // Reload data
          const modalElement = document.getElementById('saveTemplateModal');
          if (modalElement) {
            const modal = bootstrap.Modal.getInstance(modalElement);
            if (modal) {
              modal.hide(); // Close the modal
            }
          }
        } else {
          toast.error("Failed to save template.", {
            autoClose: 5000
          });
        }
      } catch (error) {
        console.error('Failed to save template:', error);
        toast.error("Failed to save template.", {
          autoClose: 5000
        });
      }
    }
  },
  async mounted() {
    this.fetchComplexityData();
  }
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px 10px;
  border-radius: 8px;
  width: 85%;
  max-height: 100%;
}

.button-container-top {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  margin-top: -18px;
}

.sticky-header {
  justify-content: center;
  align-items: center;
  top: 0;
  background: white;
  margin-top: -55px;
  margin-left: 40%;
  padding: 11px;
}

.sticky-header strong {
  color: #da6a26;
  text-align: center;
  flex-grow: 1;
}

.close-button {
  background-color: white;
  color: #da6a26; 
  border: none;
  font-size: 34px; /* Increased font size */
  margin-top: -11px;
}
.close-button:hover {
  color: rgb(15, 14, 14); 
}
.btn-reset {
  background-color: white; 
  color: #da6a26; 
  border: none;
  font-size: 20px; 
  display: flex;
  margin-top: 1%;
}
.btn-reset:hover {
  color: rgb(22, 21, 21); 
}
.delete-button {
  color: red;
  background: none;
  border: none;
  font-size: 1.5rem; 
  padding: 10px; 
  margin-left: 300px;
}

.dropdown1 {
  margin-top: 2px;
  padding: 3px;
}

.dropdownButton {
  /* padding-left: 2%; */
  display: flex;
  background-color: #1D2856 !important;
  color: white !important;
  border: none;
  border-radius: 7px;
  text-align: center;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
}
.add-button {
  background-color: white; 
  border: none;
  color: #da6a26; 
  padding: 10px 20px;
  text-align: center;
  display:list-item;
  font-size: 16px; 
  margin: 4px 2px;
  cursor: pointer; 
  border-radius: 12px;
  transition-duration: 0.4s;
  margin-left: 38%;
  margin-bottom: -4%;
}

.add-button:disabled {
  cursor: not-allowed;
}

.add-button:hover {
  background-color: #da6a26;
  color: white; 
}

.complexity-table {
  width: 100%;
  border-collapse: collapse;
}

.complexity-table th, .complexity-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.complexity-table th {
  background: #f9f9f9;
}

.tab-content {
  overflow: auto !important;
  max-height: 80vh;
  height: 80%;
  background: #F3F3F3;
  text-align: left;
}

.table-wrapper {
  max-height: 50%;
  overflow-y: auto;
}

.sticky-table-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.edit-submit-button {
  background-color: white; 
  border: none;
  color: #da6a26; 
  padding: 10px 20px;
  display:list-item;
  font-size: 16px; 
  cursor: pointer; 
  border-radius: 12px;
  transition-duration: 0.4s;
  margin-left: 46%;
}
.edit{
  margin-top: 8%;
}

.edit-submit-button:hover {
  background-color: #da6a26;
  color: white; 
}

.error-message {
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
}
.button-container {
  /* justify-content: space-between;
  margin-top: 10px;
  position: sticky;  
  padding: 10px 0;
  bottom: 0; */
  padding: auto;
  background: white;
  border-top: 1px solid #ccc;
  margin-bottom: -15px;
}
.confirm-button:hover, .cancel-button:hover {
  background-color: #da6a26;
  color: white; 
}
.btn-delete {
  background: url("../assets/icons/delete-red2.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  margin-left: 5px;
  height: 26px;
  width: 26px;
  border: none;
}

</style>
