<template>
  <ul class="tree">
    <li v-if="isEmptyData" class="no-records">
      <strong>No records found</strong>
    </li>
    <li v-else v-for="(value, key) in data" :key="key" class="tree-item">
      <div :class="['node', getNodeClass(key)]" @click="isExpandable(value) && toggleExpand(key)">
        <!-- Show expandable icon only if it's an object or array -->
        <span v-if="isExpandable(value)" class="expandable">
          <span v-if="isExpanded(key)">▼</span>
          <span v-else>▶</span>
        </span>
        <strong>{{ key }}</strong>: 
        <span v-if="!isObject(value)">{{ value }}</span> <!-- Show value if it's not an object -->
      </div>

      <!-- Render nested tree if value is an object and is expanded -->
      <div v-if="isObject(value) && isExpanded(key)" class="child-nodes">
        <TreeItem :data="value" :initialExpand="initialExpand" />
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'TreeItem',
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
    initialExpand: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      expandedKeys: {},
    };
  },
  computed: {
    isEmptyData() {
      return Array.isArray(this.data) ? this.data.length === 0 : Object.keys(this.data).length === 0;
    },
  },
  created() {
    Object.keys(this.initialExpand).forEach((key) => {
      this.expandedKeys[key] = this.initialExpand[key];
    });
  },
  methods: {
    isObject(value) {
      return typeof value === 'object' && value !== null;
    },
    isExpandable(value) {
      // Only objects or arrays that are not empty should be expandable
      return this.isObject(value) && (Array.isArray(value) ? value.length > 0 : Object.keys(value).length > 0);
    },
    toggleExpand(key) {
      this.expandedKeys[key] = !this.expandedKeys[key];
    },
    isExpanded(key) {
      return this.expandedKeys[key] !== undefined ? this.expandedKeys[key] : false;
    },
    getNodeClass(key) {
      return key.length % 2 === 0 ? 'even-node' : 'odd-node';
    },
  },
};
</script>

<style scoped>
.tree {
  list-style-type: none;
  padding-left: 0;
}

.tree-item {
  margin: 10px 0;
}

.node {
  display: flex;
  align-items: center;
  padding: 10px;
  transition: background-color 0.2s;
  cursor: pointer;
}

.even-node {
  background-color: #dff9fb;
}

.odd-node {
  background-color: #ffeaa7;
}

.node:hover {
  background-color: #f1f2f6;
}

.child-nodes {
  margin-left: 20px;
  display: block;
}

.expandable {
  margin-right: 10px;
}

.no-records {
  text-align: center;
  color: red;
  padding: 5%;
}
</style>
