<template>
  <div>
    <ViewTemplate :table-props="mappingColumns" :project-param-id="projectParamId" :mapper-param-id="mapperParamId" :default-selected-columns="defaultSelectedColumns"/>
  </div>
</template>

<script>
import { pageViews, type } from "../utils/util";
import Store from "../vuex/store.js";
import TabContentView from "./TabContentView.vue";
import ViewTemplate from "@/components/ViewTemplate.vue";

export default {
  name: pageViews.COMPONENTVIEW,
  methods: {
    setValuesToStore(type) {
      Store.commit("setType", type);
    }
  },
  mounted() {
    this.setValuesToStore(type.MAPPER_COMPONENT);
  },
  components: {
    ViewTemplate,
    TabContentView,
  },
  data() {
    return {
      uploadConfigs: {
        action: "generator",
        actionLabel: "Generator",
        title: "Mapper Config Generator",
        type: type.MAPPER_COMPONENT,
        accept: ".zip",
      },
      mappingColumns: {
        type: type.MAPPER_COMPONENT,
        isMultiSelectionAllowed: true,
        defaultSortOrder: { name: "desc", label: "Descending" },
        defaultSortColumn: { name: "Process Location", label: "Process Location" },
        configs: [
          { name: "processLocation", label: "Process Location", order: 1, sort: true, default: true },
          { name: "processFile", label: "Process File", order: 2, sort: true, default: true },
          { name: "Components", label: "Components", order: 3, sort: true, default: true }
        ],
        actions: {
          viewPopup: { label: 'View Output' },
          download: { label: 'Download' },
          calculate: { label: 'Calculate Complexity' },
        },
      },
      defaultSelectedColumns: ["processLocation", "processFile", "Components"]
    };
  },
  props: ["projectParamId", "mapperParamId"],
}
</script>